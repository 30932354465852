import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import FirstView from "./FirstView";
import SecondView from "./SecondView";

const BodyContainer = () => {
  return (
    <Box
      component="main"
      sx={{
        width: "100%",
        height: "100%",
        margin: "0",
        padding: "0",
      }}
    >
      <FirstView />
      <SecondView />
    </Box>
  );
};

export default BodyContainer;
