const imageShadow = "5px 5px 15px rgba(0, 0, 0, 0.3)";

export const styles = {
  firstGird: {
    height: "100%",
    position: "absolute",
    top: "50%",
    left: "0%",
    transform: "translateY(-50%)",
  },
  gridItem: {
    display: "flex",
    justifyContent: "center",
    marginTop: "auto",
    marginBottom: "auto",
    padding: 2,
  },
  serviceCard: {
    m: 4,
    maxWidth: "300px",
    backgroundColor: "white",
    borderRadius: "20px",
    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.7)",
    lineHeight: 1.5,
  },
  cardImgBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",

    chipBox: {
      top: "25px",
      left: "auto",
      right: "25px",
      position: "absolute",
      display: "flex",

      chip: {
        marginBottom: "5px",
        backgroundColor: "#ffffff5c",
        boxShadow: "2px 5px 10px rgba(0, 0, 0, 0.2)",
        backdropFilter: "blur(5px)",
      },
    },
  },
  cardEyeCatch: {
    borderRadius: "10px",
    marginBottom: 1,
    boxShadow: imageShadow,
  },
  cardIcon: {
    width: "65px",
    marginTop: "-40px",
    marginBottom: "10px",
    borderRadius: "15px",
    boxShadow: imageShadow,
  },
  typographyH6: {
    textAlign: "center",
  },
  typographyBody2: {
    color: "grey",
    textAlign: "center",
  },
};
