export const styles = {
  firstBox: {
    height: "100vh",
    width: "100%",
  },
  firstGrid: {
    height: "100%",

    firstItem: {
      height: "100%",
      position: "relative",
    },
  },
};
