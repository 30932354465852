import {
  Card,
  CardActionArea,
  CardContent,
  Box,
  CardMedia,
  Typography,
  Grid,
  Chip,
} from "@mui/material";
import { styles } from "./styles";
import { cardCompProps } from "./types";

const CardComponent = ({
  gridXs: gridXs,
  gridSm: gridSm,
  gridMd: gridMd,
  content: contentArray,
}: cardCompProps) => {
  return (
    <Grid container sx={styles.firstGird}>
      {contentArray.map((elem, index) => (
        <Grid item xs={gridXs} sm={gridSm} md={gridMd} sx={styles.gridItem}>
          <Card key={index} sx={styles.serviceCard}>
            <CardActionArea sx={{ position: "relative" }}>
              <Typography
                variant="h4"
                sx={{
                  position: "absolute",
                  height: "100%",
                  width: "100%",
                  backgroundColor: "#ffffff30",
                  backdropFilter: "blur(10px)",
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                come soon...
              </Typography>
              <CardContent>
                <Box sx={styles.cardImgBox}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    sx={styles.cardImgBox.chipBox}
                  >
                    <Chip
                      size="small"
                      sx={styles.cardImgBox.chipBox.chip}
                      label="iOS"
                    />
                    <Chip
                      size="small"
                      sx={styles.cardImgBox.chipBox.chip}
                      label="Android"
                    />
                  </Box>
                  <CardMedia
                    component="img"
                    image={elem.eyeCatch}
                    sx={styles.cardEyeCatch}
                  />
                  <CardMedia
                    component="img"
                    image={elem.logo}
                    sx={styles.cardIcon}
                  />
                </Box>
                <Typography variant="h6" sx={styles.typographyH6}>
                  {elem.title}
                </Typography>
                <Typography variant="body2" sx={styles.typographyBody2}>
                  {elem.intro1}
                </Typography>
                <Typography variant="body2" sx={styles.typographyBody2}>
                  {elem.intro2}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default CardComponent;
