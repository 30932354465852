import Grid from "@mui/material/Grid";
import TopBar from "../../components/WebComponents/TopBar";
import BodyContainer from "./BodyContainer";

const TopPage = () => {
    return (
        <>
            <TopBar />
            <BodyContainer />
        </>
    )
}

export default TopPage;