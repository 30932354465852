import { topBarHeight } from "styles/topBarEnv";

const typographyShadow = "0px 0px 20px rgba(0, 0, 0, 1)";

export const styles = {
  fristBox: {
    height: "100vh",
    width: "100%",
    minHeight: "500px",
  },
  firstGird: {
    height: "100%",
    width: "100%",
    itemTop: {
      height: {
        xs: topBarHeight.xs,
        sm: topBarHeight.sm,
        md: topBarHeight.md,
      },
      width: "100%",
      backgroundColor: "white",
    },
    itemMiddle: {
      flex: 1,
      height: "100%",
      width: "100%",
      justifyContent: "center",
      backgroundColor: "#3d7c825e",
      backdropFilter: "blur(2px)",
    },
    itemBottom: {
      height: {
        xs: topBarHeight.xs,
        sm: topBarHeight.sm,
        md: topBarHeight.md,
      },
      width: "100%",
      backgroundColor: "white",
    },
  },
  secondGrid: {
    height: "100%",
    width: "100%",
    justifyContent: "space-between",
    flex: 1,
    flexWrap: "nowrap",
  },
};

export const secondGridStyles = {
  border: {
    width: {
      xs: topBarHeight.xs,
      sm: topBarHeight.sm,
      md: topBarHeight.md,
      lg: "130px",
    },
    backgroundColor: "white",
  },
  titles: {
    color: "white",
    height: "100%",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginLeft: 2,
    marginRight: 2,
    paddingBottom: 10,
  },
  typographyH2: {
    fontWeight: "bold",
    marginBottom: 0,
    textShadow: typographyShadow,
  },
  typographyH5: {
    marginTop: 2,
    marginBottom: 0,
    letterSpacing: 2,
    textShadow: typographyShadow,
  },
  services: {
    p: 2,
  },
};
