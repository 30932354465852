import { useTheme } from "@emotion/react";
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  createTheme,
} from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import React from "react";
import topImage from "assets/topImage.webp";
import styled from "@emotion/styled";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import { styles, secondGridStyles } from "./styles";

const FirstView = () => {
  return (
    <Box component="section" sx={styles.fristBox}>
      <Grid container direction="column" sx={styles.firstGird}>
        <Grid item sx={styles.firstGird.itemTop}></Grid>
        <Grid item sx={styles.firstGird.itemMiddle}>
          <Grid container sx={styles.secondGrid}>
            <Grid item sx={secondGridStyles.border}></Grid>
            <Grid item sx={secondGridStyles.titles}>
              <Typography variant="h2" sx={secondGridStyles.typographyH2}>
                Web & App<br></br>
                Convenience Hub
              </Typography>
              <Typography variant="h5" sx={secondGridStyles.typographyH5}>
                Personalized Tools for Your Unique Path
              </Typography>
            </Grid>
            <Grid item sx={secondGridStyles.border}></Grid>
          </Grid>
        </Grid>
        <Grid item sx={styles.firstGird.itemBottom}></Grid>
      </Grid>
    </Box>
  );
};

export default FirstView;
