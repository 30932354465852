import React, { useState } from "react";

// 多媒体加载
import SiteLogo from "./images/logo_name.png";
import MenuIcon from "@mui/icons-material/Menu";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Drawer from "@mui/material/Drawer";
import Grid from "@mui/material/Grid";
import { topBarHeight } from "styles/topBarEnv";

// 自定义样式
const topBarStyles = {
  appBar: {
    backgroundColor: "#ffffff",
    height: {
      xs: topBarHeight.xs,
      sm: topBarHeight.sm,
      md: topBarHeight.md,
    },
    justifyContent: "center",
    boxShadow: "none",
    position: "fixed",
  },
  logoBtn: {
    width: {
      xs: "130px",
      sm: "150px",
      md: "170px",
    },
    height: "auto",
    display: "flex",
    justifyContent: "center",
  },
  pcMenuBtn: {
    color: "black",
    fontSize: "1.4em",
  },
};

const TopBar = () => {
  // 侧边弹出菜单的宽度
  const drawerWidth = 240;

  // 导航菜单组件
  const navItems = ["HOME", "Service"];

  // 手机模式，用于切换侧边弹出菜单的显示与隐藏
  const [mobileOpen, setMobileOpen] = useState(false);

  // 手机模式点击按钮弹出侧边菜单的函数
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  // 手机模式下侧边弹出菜单的组成
  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        Menu
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton sx={{ textAlign: "center" }}>
              <ListItemText primary={item} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <>
      <AppBar
        component="nav"
        position="absolute"
        sx={topBarStyles.appBar}
        elevation={20}
      >
        {/* Toolbar 是一种使 AppBar 内部内容有序排列的实用工具 */}
        <Toolbar>
          {/* 两端对齐，垂直居中 */}
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Button>
                <Box sx={topBarStyles.logoBtn}>
                  <img
                    src={SiteLogo}
                    alt="site-logo"
                    style={{ width: "100%", height: "auto" }}
                  />
                </Box>
              </Button>
            </Grid>
            <Grid item>
              {/* 电脑模式下的导航菜单 */}
              <Box sx={{ display: { xs: "none", md: "block" } }}>
                {navItems.map((item) => (
                  <Button
                    key={item}
                    sx={{
                      marginLeft: 1,
                      marginRight: 1,
                    }}
                  >
                    <Typography variant="subtitle1" sx={topBarStyles.pcMenuBtn}>
                      {item}
                    </Typography>
                  </Button>
                ))}
              </Box>
              {/* 手机模式下的弹出菜单按钮 */}
              <IconButton
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ color: "black", display: { md: "none" } }}
              >
                <MenuIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {/* 弹出式菜单 */}
      <nav>
        <Drawer
          // 弹出方向
          anchor="right"
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </>
  );
};

export default TopBar;
