import React from "react";
// import logo from './logo.svg';
import "./App.css";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import TopPage from "./pages/TopPage";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import styled from "@emotion/styled";
import BackImg from "./assets/topImage.webp";
import theme from "./styles/theme";

const backImgStyle = {
  backGround: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100vh",
    backgroundImage: `url(${BackImg})`,
    backgroundSize: "cover",
    zIndex: "-1",
    backgroundPosition: "center",
  },
};

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={backImgStyle.backGround}></Box>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<TopPage />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
