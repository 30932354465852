import CardComponent from "components/WebComponents/Card";
import { cardContent } from "components/WebComponents/Card/types";
import chineseLogo from "assets/yasashichinese-logo.webp";
import chineseEyeCatch from "assets/sumapps-chinese-eyecatch.webp";
import ipetLogo from "assets/ipet-logo.webp";
import ipetEyeCatch from "assets/ipet-eyecatch.webp";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { styles } from "./styles";

const SecondView = () => {
  return (
    <Box component="section" sx={styles.firstBox}>
      <Grid container sx={styles.firstGrid}>
        <Grid item xs={12} sx={styles.firstGrid.firstItem}>
          <SetCardCompPropsComp />
        </Grid>
      </Grid>
    </Box>
  );
};

const SetCardCompPropsComp = () => {
  const cardContentArray = [
    {
      title: "優しい中国語",
      intro1: "仮名で中国語の発音を",
      intro2: "楽しくマスターできる",
      logo: chineseLogo,
      eyeCatch: chineseEyeCatch,
      osTags: ["iOS", "Android"],
    },
    {
      title: "ipet",
      intro1: "大切なペットの日常を",
      intro2: "効果的に管理しよう",
      logo: ipetLogo,
      eyeCatch: ipetEyeCatch,
      osTags: ["iOS", "Android"],
    },
  ];

  return (
    <CardComponent
      gridXs={12}
      gridSm={6}
      gridMd={6}
      content={cardContentArray}
    />
  );
};

export default SecondView;
